import React, { useEffect, useState, useRef, useContext } from "react";
import { IntlContext } from "context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export interface BlobFile {
    name: string;
    size: number;
    content: Blob;
    type: string;
}
export interface InputProps
    extends Omit<
        Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange">,
        "value"
    > {
    name: string;
    type?: "file";
    onChange?: (value: BlobFile | null) => void;
    value?: BlobFile;
}

const InputFile: React.FC<InputProps> = ({
    className = "field-control__input",
    autoComplete = "off",
    type = "file",
    onChange,
    value,
    accept = "image/*",
    multiple,
    disabled,
    ...props
}) => {
    const intl = useContext(IntlContext);
    const [currentFile, setCurrentFile] = useState<(BlobFile & { preview: string }) | undefined>(undefined);
    const inputEl = useRef<HTMLInputElement>(null);

    let isImage =
        currentFile &&
        (currentFile.name.endsWith(".jpg") || currentFile.name.endsWith(".jpg") || currentFile.name.endsWith(".png"));

    const onChangeHanlder = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let blobFile = null;
        if (target.files) {
            const file = target.files.item(0);
            if (file) {
                blobFile = Object.assign({ name: file.name, size: file.size, type: file.type }, { content: file });
            }
        }
        onChange && onChange(blobFile);
    };

    useEffect(() => {
        if (!value) {
            setCurrentFile(undefined);
            return;
        }

        if (value.content) {
            const preview = URL.createObjectURL(value.content);
            const newFile = Object.assign({ ...value }, { preview });
            setCurrentFile(newFile);

            return () => {
                if (newFile) {
                    URL.revokeObjectURL(newFile.preview);
                }
            };
        }
    }, [value]);

    return (
        <div className={"field-control-file" + (currentFile ? " has-file" : "")}>
            <div className="field-control-file__preview">
                {currentFile && isImage && (
                    <img className="field-control-file__img" src={currentFile.preview} alt={currentFile.name} />
                )}
                {currentFile && !isImage && (
                    <p className="field-control-file__name">
                        <a
                            href={currentFile.preview}
                            download={currentFile.name}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            rel="noopener noreferrer"
                        >
                            {currentFile.name}
                        </a>
                    </p>
                )}
                {!disabled && (
                    <input
                        className="field-control-file__input"
                        autoComplete={autoComplete}
                        type={type}
                        onChange={onChangeHanlder}
                        ref={inputEl}
                        accept={accept}
                        {...props}
                    />
                )}
            </div>
            {!disabled && (
                <div className="field-control-file__info">
                    <button
                        type="button"
                        className="field-control-file__btn btn--secondary btn--icon"
                        onClick={() => inputEl.current && inputEl.current.click()}
                    >
                        <Icon
                            icon={{ prefix: "fab", iconName: "download" }}
                            size="2x"
                            title={intl.formatMessage("generic.action.upload.add")}
                        />
                    </button>
                    {currentFile ? (
                        <button
                            type="button"
                            className="field-control-file__btn btn--secondary btn--icon btn--icon-small"
                            onClick={() => {
                                if (inputEl.current) {
                                    inputEl.current.value = "";
                                    onChange && onChange(null);
                                }
                            }}
                        >
                            <Icon
                                icon={{ prefix: "fab", iconName: "times" }}
                                size="2x"
                                title={intl.formatMessage("generic.action.upload.remove")}
                            />
                        </button>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default InputFile;
