import React, { useCallback, useContext } from "react";
import { AuthContext } from "context";

export interface ClientProviderInterface {
    currentClient: any;
    setCurrentClient: (id: string) => void;
    defaultPerimetreId: string;
}

const ClientContext = React.createContext<ClientProviderInterface>({
    currentClient: "",
    setCurrentClient: () => null,
    defaultPerimetreId: "",
});

export const CLIENT_KEY: string = "forexfinance_client";

const Consumer = ClientContext.Consumer;

const Provider: React.FC = ({ children }) => {
    const auth = useContext(AuthContext);
    const clients = auth.account?.clients;
    const urlsWithIds = [
        "/admin_client/societe/",
        "/admin_client/contrepartie/",
        "/financement/saisie/",
        "/financement/fiche/",
    ];

    const defaultClient = clients
        ?.map((client) => {
            if (client.id === auth.account?.params.default_client) {
                return client;
            }
            return null;
        })
        .filter((item) => item !== null)
        .shift();

    const storedClient = localStorage.getItem(CLIENT_KEY);
    let currentClient = defaultClient ?? (clients ? clients[0] : {});

    if (storedClient) {
        currentClient = JSON.parse(storedClient);
    }

    const setCurrentClient = useCallback(
        (client_id: string) => {
            let url = "";

            urlsWithIds.forEach((path: string) => {
                if (window.location.pathname.includes(path)) {
                    url = window.location.origin;

                    if (path === "/financement/fiche/") {
                        url += "/financement/";
                    } else {
                        url += path;
                    }
                }
            });

            if (url === "") {
                url = window.location.href;
            }

            clients?.map((clientItem) => {
                if (clientItem.id === client_id) {
                    localStorage.setItem(CLIENT_KEY, JSON.stringify(clientItem));
                    window.location.href = url;
                }
            });
        },
        [clients, urlsWithIds]
    );

    return (
        <ClientContext.Provider
            value={{
                currentClient,
                setCurrentClient,
                defaultPerimetreId: auth.account?.params.default_perimetre || "",
            }}
        >
            {children}
        </ClientContext.Provider>
    );
};

export { Provider, Consumer, ClientContext as Context };
