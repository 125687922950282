import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// @TODO set locale with user
import "moment/locale/fr";
import "moment/locale/es";
import "helpers/date/moment-ferie-fr";
moment.locale("fr");

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
