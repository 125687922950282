import React from "react";
import { withClient, ClientProviderShape } from "context/Client";
import { default as PerimetreService, PerimetreInterface } from "dataProvider/Analyse/Perimetres";
import dateFormatIsValid from "helpers/form/dateFormatIsValid";
import Loader from "app/Analyse/components/Loader";
import moment from "moment";

export enum PeriodDisplay {
    Total = "total",
    Period = "period",
}

export enum Periodicity {
    Annual = 12,
    Biannual = 6,
    Quarterly = 3,
    Monthly = 1,
}

export enum AmountType {
    UTILISE = "utilise",
    DISPONIBLE = "disponible",
    BOTH = "both",
}

export enum DurationMode {
    REAL = "real",
    LIGNES = "lignes",
}

export enum GroupBy {
    CONTRAT_CATEGORY = "contrat_category",
    CLE_ANALYTIQUE = "cle_analytique",
    ACTIF_FINANCE = "actif_finance",
    DEVISE_CODE = "devise_code",
    PRETEUR = "preteur",
    TAUX_TYPE = "taux_type",
}

export enum Prorata {
    COMPTABLE = "COMPTABLE",
    FINANCIER = "FINANCIER",
}

function formatDate(date: Date | string | number) {
    date = new Date(date);
    let month = `${date.getMonth() + 1}`,
        day = `${date.getDate()}`,
        year = `${date.getFullYear()}`;

    if (month.length < 2) {
        month = "0" + month;
    }
    if (day.length < 2) {
        day = "0" + day;
    }

    return [year, month, day].join("-");
}

interface ISetParamsParams {
    date: string | number | Date;
    perimetreId: string;
    devise?: string;
    amountType?: AmountType;
    durationMode?: DurationMode;
    placements?: "0" | "1";
}

interface ISetPeriodParams {
    periodDisplay: PeriodDisplay;
    periodicite: Periodicity;
    periodStart?: string;
    periodEnd?: string;
}

interface ISetGroupByParams {
    groupBy: GroupBy;
    cleAnalytique?: string;
}

interface ISetNN1Params {
    dateDebutN1: string;
    dateFinN1: string;
    calculDateN1: "start" | "end";
    dateDebutN: string;
    dateFinN: string;
    calculDateN: "start" | "end";
}

interface ISetPeriodsCompletParams {
    dateDebut1?: string;
    dateFin1?: string;
    dateDebut2?: string;
    dateFin2?: string;
}

interface ISetIcneParams {
    date_icne1?: string;
    date_icne2?: string;
    prorata?: Prorata;
}

export interface AnalyseFilterProviderInterface {
    perimetreId?: string;
    devise: string;
    date: string;
    amountType?: AmountType;
    durationMode?: DurationMode;
    placements?: "0" | "1";
    groupBy?: GroupBy;
    prorata?: Prorata;
    cleAnalytique?: string;
    periodDisplay?: PeriodDisplay;
    periodicite?: Periodicity;
    periodStart?: string;
    periodEnd?: string;
    periodsComplet: ISetPeriodsCompletParams;
    dateDebutN1?: string;
    dateFinN1?: string;
    calculDateN1?: "start" | "end";
    dateDebutN?: string;
    dateFinN?: string;
    calculDateN?: "start" | "end";
    date_icne1?: string;
    date_icne2?: string;
    setPerimetreId: (perimetreId: string) => void;
    setDate: (date: string | number | Date) => void;
    setDevise: (currency: string) => void;
    setParams: (params: ISetParamsParams) => void;
    setPeriodParams: (params: ISetPeriodParams) => void;
    setGroupByParams: (params: ISetGroupByParams) => void;
    setNN1Params: (params: ISetNN1Params) => void;
    setPeriodsCompletParams: (params: ISetPeriodsCompletParams) => void;
    setIcneParams: (params: ISetIcneParams) => void;
    refreshPerimetres: () => void;
    perimetres: Array<PerimetreInterface>;
    status: "LOADING" | "IDLE";
}

interface AnalyseFilterProviderState extends AnalyseFilterProviderInterface {}

const AnalyseFilterContext = React.createContext<AnalyseFilterProviderInterface>({
    setPerimetreId: () => {},
    setDate: () => {},
    setDevise: () => {},
    setParams: () => {},
    setPeriodParams: () => {},
    setGroupByParams: () => {},
    setNN1Params: () => {},
    setPeriodsCompletParams: () => {},
    setIcneParams: () => {},
    refreshPerimetres: () => {},
    perimetres: [],
    devise: "EUR",
    periodsComplet: {},
    date: formatDate(Date.now()),
    status: "IDLE",
});

interface AnalyseFilterProviderProps {
    Client: ClientProviderShape;
}

const Consumer = AnalyseFilterContext.Consumer;

class AnalyseProvider extends React.Component<AnalyseFilterProviderProps, AnalyseFilterProviderState> {
    constructor(props: Readonly<AnalyseFilterProviderProps>) {
        super(props);
        this.state = {
            perimetreId: undefined,
            devise: "EUR",
            amountType: AmountType.UTILISE,
            durationMode: DurationMode.REAL,
            placements: "0",
            groupBy: GroupBy.CONTRAT_CATEGORY,
            prorata: Prorata.COMPTABLE,
            periodDisplay: PeriodDisplay.Total,
            periodicite: Periodicity.Annual,
            periodStart: formatDate(Date.now()),
            calculDateN1: "end",
            calculDateN: "start",
            date_icne1: moment().format("YYYY-MM-DD"),
            date_icne2: moment().format("YYYY-12-31"),
            periodsComplet: {},
            setPerimetreId: this.setPerimetreId,
            setDevise: this.setDevise,
            setDate: this.setDate,
            setParams: this.setParams,
            setPeriodParams: this.setPeriodParams,
            setGroupByParams: this.setGroupByParams,
            setNN1Params: this.setNN1Params,
            setPeriodsCompletParams: this.setPeriodsCompletParams,
            setIcneParams: this.setIcneParams,
            refreshPerimetres: this.refreshPerimetres,
            perimetres: [],
            status: "LOADING",
            date: formatDate(Date.now()),
        };
    }

    componentDidMount = () => {
        this.loadPerimetres();
    };

    componentDidUpdate = (prevProps: AnalyseFilterProviderProps) => {
        if (this.props.Client.currentClient.id !== prevProps.Client.currentClient.id) {
            this.loadPerimetres();
        }
    };

    refreshPerimetres = () => {
        this.loadPerimetres();
    };

    loadPerimetres = () => {
        if (!this.props.Client.currentClient.id) {
            this.setState({
                status: "IDLE",
            });
            return;
        }

        this.setState({
            status: "LOADING",
        });

        return PerimetreService.list(this.props.Client.currentClient.id).then((perimetres) => {
            // use default perimetre if in results
            let currentPerimetre = perimetres
                .map((perimetre) => {
                    if (perimetre.id === this.props.Client.defaultPerimetreId) {
                        return perimetre;
                    }
                    return null;
                })
                .filter((item) => item !== null)
                .shift();

            // or first in results
            if (!currentPerimetre) {
                currentPerimetre = perimetres.length ? perimetres[0] : null;
            }

            this.setState({
                perimetres,
                status: "IDLE",
                perimetreId: currentPerimetre ? currentPerimetre.id : undefined,
                devise: currentPerimetre ? currentPerimetre.devise : "EUR",
            });
        });
    };

    setParams = (params: ISetParamsParams) => {
        if (!dateFormatIsValid(params.date)) {
            return;
        }
        let newState = {
            perimetreId: params.perimetreId,
            date: formatDate(params.date),
            devise: params.devise || this.state.devise,
            amountType: params.amountType || this.state.amountType,
            durationMode: params.durationMode || this.state.durationMode,
            placements: params.placements || this.state.placements,
        };
        if (newState.perimetreId !== this.state.perimetreId) {
            const perimetre = this.getPerimetre(params.perimetreId);
            newState.devise = perimetre?.devise || "EUR";
        }
        this.setState(newState);
    };

    setPeriodParams = (params: ISetPeriodParams) => {
        if (
            (dateFormatIsValid(params.periodStart) && !dateFormatIsValid(params.periodEnd)) ||
            (!dateFormatIsValid(params.periodStart) && dateFormatIsValid(params.periodEnd))
        ) {
            return;
        }
        this.setState(params);
    };

    setGroupByParams = (params: ISetGroupByParams) => {
        this.setState(params);
    };

    setNN1Params = (params: ISetNN1Params) => {
        if (
            !dateFormatIsValid(params.dateDebutN1) ||
            !dateFormatIsValid(params.dateFinN1) ||
            !dateFormatIsValid(params.dateDebutN) ||
            !dateFormatIsValid(params.dateFinN)
        ) {
            return;
        }
        this.setState(params);
    };

    setIcneParams = (params: ISetIcneParams) => {
        this.setState(params);
    };

    setPeriodsCompletParams = (params: ISetPeriodsCompletParams) => {
        if (
            !dateFormatIsValid(params.dateDebut1) ||
            !dateFormatIsValid(params.dateFin1) ||
            !dateFormatIsValid(params.dateDebut2) ||
            !dateFormatIsValid(params.dateFin2)
        ) {
            return;
        }
        this.setState({
            periodsComplet: params,
        });
    };

    getPerimetre = (perimetreId: string) => {
        return this.state.perimetres.find((perimetre) => {
            return perimetre.id === perimetreId;
        });
    };

    setPerimetreId = (perimetreId: string) => {
        const perimetre = this.getPerimetre(perimetreId);
        this.setState({
            perimetreId: perimetre?.id,
            devise: perimetre?.devise || "EUR",
        });
    };

    setDate = (date: string | number | Date) => {
        this.setState({
            date: formatDate(date),
        });
    };

    setDevise = (currency: string) => {
        this.setState({
            devise: currency,
        });
    };

    render() {
        if (this.state.status === "LOADING") {
            return <Loader />;
        }

        return <AnalyseFilterContext.Provider value={this.state}>{this.props.children}</AnalyseFilterContext.Provider>;
    }
}

const Provider = withClient(AnalyseProvider);

export { Provider, Consumer, AnalyseFilterContext as Context };
