import React from "react";
import fetch from "dataProvider/fetch";
import AuthenticationService, { AuthData } from "dataProvider/AuthenticationService";
import getIn from "helpers/getIn";
import Acl from "./Acl";
import { CLIENT_KEY } from "context/Client/Context";

interface Client {
    id: string;
    nom: string;
    nom_usage?: string;
    logo_id: string | null;
}

interface Account {
    user: {
        id: string;
        gender: string;
        first_name: string;
        last_name: string;
        username: string;
        email: string;
        language_code: string;
        role: {
            id: string;
            name: string;
        };
    };
    acl: Acl;
    resources?: Array<Client>; // @TODO : or sociétés
    clients?: Array<Client>;
    params: {
        default_client: string;
        default_perimetre: string;
    };
}

export interface AuthenticationProviderInterface extends Readonly<{}> {
    isLoggedIn: Boolean;
    account: Account | null;
    login: (authInfo: { username: string; password: string }) => Promise<void>;
    logout: () => Promise<void>;
    isAllowed: (capability: string) => boolean;
    filterAllowed: (actions: HashMap<any>, acl: HashMap<string>) => HashMap<any>;
    updateAccount: () => Promise<void>;
}

export const ACCOUNT_KEY: string = "forexfinance_account";

function storeDataToAccount(data: AuthData): Account {
    const account = {
        user: getIn(data, "user"),
        acl: new Acl(),
        resources: getIn(data, "resources"),
        clients: getIn(data, "clients"),
        params: {
            default_client: data.params.default_client,
            default_perimetre: data.params.default_perimetre,
        },
    };

    data.capabilities.forEach((capability) => {
        account.acl.allow(capability);
    });
    return account;
}

function getAccount(): Account | null {
    if (fetch.sessionIsExpired()) {
        return null;
    }
    try {
        const account = localStorage.getItem(ACCOUNT_KEY);
        if (account) {
            return storeDataToAccount(JSON.parse(account));
        }
        return null;
    } catch (err) {
        return null;
    }
}

function isLoggedIn(): boolean {
    return !!getAccount() && !fetch.sessionIsExpired();
}

const AuthContext = React.createContext<AuthenticationProviderInterface>({} as AuthenticationProviderInterface);

class AuthProvider extends React.Component<Readonly<{}>, AuthenticationProviderInterface> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            isLoggedIn: false,
            account: null,
            login: this.login,
            logout: this.logout,
            isAllowed: this.isAllowed,
            filterAllowed: this.filterAllowed,
            updateAccount: this.updateAccount,
        };
    }

    componentDidMount = async () => {
        if (isLoggedIn()) {
            try {
                this.updateAccount();
            } catch (e) {
                this.logout();
            }
        }
    };

    isAllowed = (capability: string) => {
        return !!this.state.account && this.state.account.acl.isAllowed(capability);
    };

    filterAllowed = (actions: HashMap<any>, acl: HashMap<string>) => {
        const actionsAllowed: HashMap<any> = {};
        for (const action in actions) {
            if (!acl[action]) {
                actionsAllowed[action] = actions[action];
            } else if (this.isAllowed(acl[action])) {
                actionsAllowed[action] = actions[action];
            }
        }
        return actionsAllowed;
    };

    login = async ({ username, password }: { username: string; password: string }) => {
        const data = await AuthenticationService.login(username, password);
        const account = Object.assign({}, data);
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({ isLoggedIn: true, account: storeDataToAccount(account) });
    };

    logout = async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const bugIE = await AuthenticationService.logout();
        } finally {
            localStorage.removeItem(ACCOUNT_KEY);
            localStorage.removeItem(CLIENT_KEY);
            fetch.clearSession();
            this.setState({ isLoggedIn: false, account: null });
            window.location.href = "/";
        }
    };

    updateAccount = async () => {
        let data: any;

        try {
            data = await AuthenticationService.fresh();
        } catch (e) {
            this.logout();
        }

        if (data !== undefined && data?.error !== undefined) {
            this.logout();
        }

        if (data !== undefined && data?.user !== undefined) {
            const account = Object.assign({}, data);
            localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
            this.setState({ isLoggedIn: true, account: storeDataToAccount(account) });
        }
    };

    render() {
        return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
    }
}

const Consumer = AuthContext.Consumer;

export { AuthProvider as Provider, Consumer, AuthContext as Context };
